body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ins-app{
  padding: 10px;
}

.ins-top-menu ul {
  list-style: none; /*убираем маркеры списка*/
  margin: 0; /*убираем отступы*/
  padding-left: 0;
  padding-bottom: 5px;
  display: flex;
}

.ins-top-menu a {
  text-decoration: none; /*убираем подчеркивание текста ссылок*/
  background: #30A8E6; /*добавляем фон к пункту меню*/
  color: #fff; /*меняем цвет ссылок*/
  padding: 5px; /*добавляем отступ*/
  font-family: arial; /*меняем шрифт*/
  border-radius: 4px; /*добавляем скругление*/
  -moz-transition: all 0.3s 0.01s ease; /*делаем плавный переход*/
  -o-transition: all 0.3s 0.01s ease;
  -webkit-transition: all 0.3s 0.01s ease;
}

  .ins-top-menu a:hover {
      background: #1C85BB; /*добавляем эффект при наведении*/
  }

.ins-top-menu li {
  margin-right: 5px; /*Добавляем отступ у пунктов меню*/
} 

.ms-Dropdown-container{
  padding-right: 5px;
}